import React, { useEffect, useState } from "react";
import AOS from "aos";
import * as yup from "yup";
import axios from "axios";
import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import { API_URI } from "../config";

import {
  HeroBackground,
  HeroContainer,
  InternalWrapper,
  HeroPicture,
  TextDiv,
  Title,
  IntroductionDiv,
  CTAButton,
  GridPanelContainer,
  GridPanel,
  GridPiture,
  GridTextContainer,
  GridTitle,
  DropDownArrow,
  GridList,
  GridListElement,
  GridListParagraph,
  ApplyNowBackground,
  ApplyNowContainer,
  ApplyNowTitle,
  ApplyTextContainer,
  ApplyNowSubtitleDesktop,
  ApplyNowSubTitleMobile,
  ApplyNowText,
  ContactInfo,
  ContactElement,
  BenefitsContainer,
  Benefits,
  BenefitsTitle,
  BenefitsList,
  BenefitElement,
  FormBackground,
  FormContainer,
  FormGrid,
  FormGroup,
  FormInputError,
  FormInputLabel,
  FormInput,
  FormSelect,
  SelectListContainer,
  SelectOption,
  FormFieldset,
  FormRadioLabel,
  FormRadio,
  SubmitButton,
  ButtonAndMessage,
  LearnMoreContainer,
  LearnMoreText,
  PhoneIcon,
  LearnMoreButton,
  LearnMoreDiv,
  TextSection,
} from "../styles/pages/careers";

import HeroAvif from "../images/careers/avif/hero.avif";
import HeroPng from "../images/careers/jpg/hero.png";

import aideHourlyAvif from "../images/careers/avif/aide_hourly.avif";
import aideHourlyJpg from "../images/careers/jpg/aide_hourly.jpg";
import aideLiveInAvif from "../images/careers/avif/aide_live-in.avif";
import aideLiveInJpg from "../images/careers/jpg/aide_live-in.jpg";
import nurseAvif from "../images/careers/avif/nurse.avif";
import nurseJpg from "../images/careers/jpg/nurse.jpg";

import DownArrow from "../images/careers/svg/down.svg";
import DownArrowRed from "../images/careers/svg/down_red.svg";

import telephoneRing from "../images/careers/svg/phone-ring.svg";
import { CONTACT } from "../config/urls";

let applicationSchema = yup.object().shape({
  job: yup.string("not a valid value").required("required field"),
  lastName: yup.string("not a valid value").required("required field"),
  firstName: yup.string("not a valid value").required("required field"),
  email: yup.string("not a valid value").email().required("required field"),
  phone: yup
    .string("not a valid value")
    .matches(/^[0-9\-+()]+$/, "Phone number is not valid")
    .required("required field"),
  address: yup.string("not a valid value").required("required field"),
  city: yup.string("not a valid value").required("required field"),
  state: yup.string("not a valid value").required("required field"),
  postal: yup.string("not a valid value").required("required field"),
  homeHealthNewJerseyCertified: yup
    .string("not a valid value")
    .matches(/^(yes|no)$/, "not a valid value")
    .required("required field"),
  drive: yup
    .string("not a valid value")
    .matches(/^(yes|no)$/, "not a valid value")
    .required("required field"),
  scheduleHours: yup
    .array("not a valid value")
    .of(
      yup
        .string("not a valid value")
        .matches(
          /^(Live-in|Hourly \(daytime\)|Hourly \(overnight\))$/,
          "not a valid value"
        )
        .required("required field")
    )
    .min(1, "required field")
    .required("required field"),
  scheduleDays: yup
    .string("not a valid value")
    .matches(
      /^(I am NOT available on weekdays|I am NOT available on weekends|I can work every other weekend)$/,
      "not a valid value"
    )
    .required("required field"),
});
const benefits = [
  "Health Insurance offered to Full time employees",
  "PPE supplies and uniform provided",
  "401K plan for all employees",
  "Bi-weekly pay",
  "Unlimited Referral Bonuses",
  "Time and a half on major holidays",
  "CHHA re-certification assistance",
];
const jobs = [
  {
    title: "CERTIFIED HOME HEALTH AIDE - Hourly",
    imageAvif: aideHourlyAvif,
    imageJpg: aideHourlyJpg,
    info: [
      "Life Home Care is actively hiring all Certified Home Health Aides (CHHA).",
      "Starting pay rate $15-16/hr.",
      "$200 sign-on bonus after 300/hrs worked hourly, live-in after 3 months employed.",
      "Full-time hours guaranteed, all shifts available.",
      "Morning, afternoon, evening, overnight, weekends, part-time, full-time.",
      "All you need is an active CHHA license in New Jersey.",
      "Valid Driver’s license, reliable car and car insurance is required for hourly workers.",
      "We are actively hiring and hosting orientations every week!",
    ],
  },
  {
    title: "CERTIFIED HOME HEALTH AIDE - Live in",
    imageAvif: aideLiveInAvif,
    imageJpg: aideLiveInJpg,
    info: [
      "Live-in Certified Home Health Aide cases available to start immediately.",
      "Long-term permanent Live-in cases available 7 days a week.",
      "Temporary Fill-in/floater cases with flexible days.",
      "Starting rate $170.00/day - $180.00/day.",
      "$200 sign-on bonus after 3 months employed as a Live-in Caregiver.",
    ],
  },
  {
    title: "REGISTERED NURSE",
    imageAvif: nurseAvif,
    imageJpg: nurseJpg,
    paragraph:
      "Life home care is currently hiring registered nurses to work part-time or per diem hours as a field nurse supervisor.",
    info: [
      "Must be available evenings and weekends, if needed with notice.",
      "Graduate of an accredited School of Nursing required.",
      "A licensed RN in good standing, holding a current license in NJ.",
      "Availability to provide home visits to private home care clients.",
      "Orientation, supervision, and education of Certified Home Health Aides.",
      "Availability to share on-call responsibilities when needed.",
      "Experience in home care, education, or community nursing preferred.",
    ],
  },
];

const Careers = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  const [openJob, setOpenJob] = useState("");
  const [openApplication, setOpenApplication] = useState(false);
  const [formResponse, setFormResponse] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [openSelect, setOpenSelect] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (event) => {
    setFormErrors({});
    setSubmitSuccess(false);
  };

  const handleFormResponse = (question, response) => {
    handleChange();
    if (formResponse[question] === response) {
      return setFormResponse((prevState) => ({ ...prevState, [question]: "" }));
    }
    setFormResponse((prevState) => ({ ...prevState, [question]: response }));
  };

  const handleScheduleHours = (response) => {
    // If there is no response yet
    if (!Array.isArray(formResponse.scheduleHours)) {
      handleChange();
      setFormResponse((prevState) => ({
        ...prevState,
        scheduleHours: [response],
      }));
      return;
    }

    // If the response already exists, we need to remove it
    if (formResponse.scheduleHours.includes(response)) {
      handleChange();
      const newResponseArray = formResponse.scheduleHours.filter(function (
        element
      ) {
        return element !== response;
      });
      setFormResponse((prevState) => ({
        ...prevState,
        scheduleHours: newResponseArray,
      }));
      return;
    }

    // If there are other responses, we simply add the new one.
    const newResponseArray = [...formResponse.scheduleHours, response];
    setFormResponse((prevState) => ({
      ...prevState,
      scheduleHours: newResponseArray,
    }));
    handleChange();
  };

  const handleJobClick = (jobTitle) => {
    handleChange();
    if (openJob === jobTitle) {
      return setOpenJob("");
    }

    setOpenJob(jobTitle);
  };

  const submit = async () => {
    try {
      await applicationSchema.validate(formResponse, { abortEarly: false });
      await axios.post(`${API_URI}/api/umbrella/jobs/`, formResponse);
      setFormResponse({});
      setFormErrors({});
      setSubmitSuccess(true);
    } catch (err) {
      if (err.isAxiosError || !err.inner) {
        return setFormErrors({
          axios: "Something went wrong. Please try again.",
        });
      }

      const allErrors = {};

      err.inner.forEach((error) => {
        allErrors[error.path] = error.errors[0];
      });

      setFormErrors(allErrors);
    }
  };

  return (
    <Layout hasTransparentTopBar>
      <Seoinfo
        siteName={"Life Health Group"}
        titlePage={"Join Our Team | Life Health Group"}
        description={
          "Life Health Group is looking for passionate people to join our team and work towards promoting better quality of life."
        }
        keywords={
          "home care, home therapy, durable medical equipment, ride, senior, care services, home, health, nursing, hospice, life health group"
        }
        type={"website"}
        url={"/careers"}
        imageFacebook={"/seo/seo_image.png"}
      />

      <HeroBackground>
        <HeroContainer>
          <HeroPicture>
            <source
              srcset={HeroAvif}
              type="image/avif"
              media="(min-width: 460px)"
            />
            <img srcset={HeroPng} alt="" type="image/jpg" />
          </HeroPicture>

          <InternalWrapper>
            <TextDiv data-aos="fade">
              <Title>
                JOIN THE LIFE <br />
                HOME CARE TEAM
              </Title>
              <IntroductionDiv>
                These are the open positions we have available. <br />
                <CTAButton
                  href="#apply"
                  onClick={() => setOpenApplication(true)}
                >
                  APPLY NOW
                </CTAButton>
              </IntroductionDiv>
            </TextDiv>
          </InternalWrapper>
        </HeroContainer>
      </HeroBackground>
      {jobs.map((job, index) => {
        return (
          <GridPanelContainer>
            <GridPanel>
              <GridTextContainer reverse={index % 2 !== 0}>
                <GridTitle onClick={() => handleJobClick(job.title)}>
                  {job.title}
                  <DropDownArrow
                    isActive={openJob === job.title}
                    src={DownArrow}
                  />
                </GridTitle>
                <GridList isActive={openJob === job.title}>
                  {job.paragraph && (
                    <GridListParagraph>{job.paragraph}</GridListParagraph>
                  )}
                  {job.info.map((element) => (
                    <GridListElement>{element}</GridListElement>
                  ))}
                </GridList>
              </GridTextContainer>
              <GridPiture reverse={index % 2 !== 0}>
                <source srcset={job.imageAvif} type="image/avif" />
                <img srcset={job.imageJpg} alt="" type="image/jpg" />
              </GridPiture>
            </GridPanel>
          </GridPanelContainer>
        );
      })}
      <BenefitsContainer isActive>
        <Benefits>
          <BenefitsTitle>BENEFITS</BenefitsTitle>
          <BenefitsList>
            {benefits.map((benefit) => (
              <BenefitElement>{benefit}</BenefitElement>
            ))}
          </BenefitsList>
        </Benefits>
      </BenefitsContainer>
      <ApplyNowBackground>
        <ApplyNowContainer>
          <div onClick={() => setOpenApplication(!openApplication)}>
            <ApplyNowTitle id="apply">
              Apply Now
              <DropDownArrow isActive={openApplication} src={DownArrowRed} />
            </ApplyNowTitle>
            <ApplyNowSubTitleMobile>
              Fill out the application form to start the process.
            </ApplyNowSubTitleMobile>
          </div>

          <ApplyTextContainer isActive={openApplication}>
            <div>
              <ApplyNowSubtitleDesktop>
                Fill out the application form to start the process.
              </ApplyNowSubtitleDesktop>
              <ApplyNowText>
                Do you require further details? Need help? Please contact us on
                our confidential phone line to discuss how we can best support
                you.
              </ApplyNowText>
            </div>
            <ContactInfo>
              <ContactElement className="location">
                70 South Orange Ave, Livingston, NJ 07039
              </ContactElement>
              <ContactElement className="phone">
                {CONTACT.PHONE_TO_DISPLAY}
              </ContactElement>
              <ContactElement className="mail">
                hello@homecare.life
              </ContactElement>
            </ContactInfo>
          </ApplyTextContainer>
        </ApplyNowContainer>
      </ApplyNowBackground>

      <FormBackground isActive={openApplication}>
        <FormContainer>
          <FormGrid>
            <FormGroup>
              <FormInputLabel>Applying for * </FormInputLabel>
              <FormSelect
                isActive={openSelect}
                type="button"
                value={formResponse.questionId}
                // onChange={(e) => handleFormResponse("questionId", e.target.value)}
                onClick={() => setOpenSelect(!openSelect)}
              >
                {formResponse.job || "Select a position"}
                <SelectListContainer isActive={openSelect}>
                  {jobs.map((job) => (
                    <SelectOption
                      onClick={() => handleFormResponse("job", job.title)}
                    >
                      {job.title}
                    </SelectOption>
                  ))}
                </SelectListContainer>
              </FormSelect>
              <FormInputError>
                {formErrors.job && formErrors.job}
              </FormInputError>
              <FormInputLabel>First Name *</FormInputLabel>
              <FormInput
                type="text"
                placeholder="Enter Your First Name"
                value={formResponse.firstName || ""}
                onChange={(e) =>
                  handleFormResponse("firstName", e.target.value)
                }
              />
              <FormInputError>
                {formErrors.firstName && formErrors.firstName}
              </FormInputError>
              <FormInputLabel>Last Name *</FormInputLabel>
              <FormInput
                type="text"
                placeholder="Enter Your Last Name"
                value={formResponse.lastName || ""}
                onChange={(e) => handleFormResponse("lastName", e.target.value)}
              />
              <FormInputError>
                {formErrors.lastName && formErrors.lastName}
              </FormInputError>
              <FormInputLabel>Email Address *</FormInputLabel>
              <FormInput
                type="text"
                placeholder="Enter Your Email Address"
                value={formResponse.email || ""}
                onChange={(e) => handleFormResponse("email", e.target.value)}
              />
              <FormInputError>
                {formErrors.email && formErrors.email}
              </FormInputError>
            </FormGroup>

            <FormGroup>
              <FormInputLabel>Phone *</FormInputLabel>
              <FormInput
                type="text"
                placeholder="Enter Your Phone"
                value={formResponse.phone || ""}
                onChange={(e) => handleFormResponse("phone", e.target.value)}
              />
              <FormInputError>
                {formErrors.phone && formErrors.phone}
              </FormInputError>

              <FormInputLabel>Address *</FormInputLabel>
              <FormInput
                type="text"
                placeholder="Enter Your Address"
                value={formResponse.address || ""}
                onChange={(e) => handleFormResponse("address", e.target.value)}
              />
              <FormInputError>
                {formErrors.address && formErrors.address}
              </FormInputError>

              <FormInput
                type="text"
                placeholder="City"
                value={formResponse.city || ""}
                onChange={(e) => handleFormResponse("city", e.target.value)}
              />
              <FormInputError>
                {formErrors.city && formErrors.city}
              </FormInputError>

              <FormInput
                type="text"
                placeholder="State/ Province"
                value={formResponse.state || ""}
                onChange={(e) => handleFormResponse("state", e.target.value)}
              />
              <FormInputError>
                {formErrors.state && formErrors.state}
              </FormInputError>

              <FormInput
                type="text"
                placeholder="Postal"
                value={formResponse.postal || ""}
                onChange={(e) => handleFormResponse("postal", e.target.value)}
              />
              <FormInputError>
                {formErrors.postal && formErrors.postal}
              </FormInputError>
            </FormGroup>
          </FormGrid>

          <FormGrid>
            <FormGroup>
              <FormInputLabel>
                Are you currently licensed in the state of New Jersey as a
                Certified Home Health Aide? *
              </FormInputLabel>
              <FormInputError radio>
                {formErrors.homeHealthNewJerseyCertified &&
                  formErrors.homeHealthNewJerseyCertified}
              </FormInputError>
              <FormFieldset
                margin={"1.25rem 0 0 0"}
                marginMobile={"1.25rem 0 2rem 0"}
              >
                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="yes"
                    name="homeHealthNewJerseyCertified"
                    checked={
                      formResponse.homeHealthNewJerseyCertified === "yes"
                    }
                    onClick={(e) =>
                      handleFormResponse(
                        "homeHealthNewJerseyCertified",
                        e.target.value
                      )
                    }
                  />
                  Yes
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="no"
                    name="homeHealthNewJerseyCertified"
                    checked={formResponse.homeHealthNewJerseyCertified === "no"}
                    onClick={(e) =>
                      handleFormResponse(
                        "homeHealthNewJerseyCertified",
                        e.target.value
                      )
                    }
                  />
                  No
                </FormRadioLabel>
              </FormFieldset>

              <FormInputLabel>Do you drive? *</FormInputLabel>
              <FormInputError radio>
                {formErrors.drive && formErrors.drive}
              </FormInputError>
              <FormFieldset>
                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="yes"
                    name="drive"
                    checked={formResponse.drive === "yes"}
                    onClick={(e) => handleFormResponse("drive", e.target.value)}
                  />
                  Yes
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="no"
                    name="drive"
                    checked={formResponse.drive === "no"}
                    onClick={(e) => handleFormResponse("drive", e.target.value)}
                  />
                  No
                </FormRadioLabel>
              </FormFieldset>
            </FormGroup>

            <FormGroup>
              <FormInputLabel>
                What is your schedule availability? *
              </FormInputLabel>
              <FormInputError radio scheduleHours>
                {formErrors.scheduleHours && formErrors.scheduleHours}
              </FormInputError>
              <FormFieldset>
                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="Live-in"
                    name="scheduleHours1"
                    checked={formResponse.scheduleHours?.includes("Live-in")}
                    onClick={(e) => handleScheduleHours(e.target.value)}
                  />
                  Live-in
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="Hourly (daytime)"
                    name="scheduleHours2"
                    checked={formResponse.scheduleHours?.includes(
                      "Hourly (daytime)"
                    )}
                    onClick={(e) => handleScheduleHours(e.target.value)}
                  />
                  Hourly (daytime)
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="Hourly (overnight)"
                    name="scheduleHours3"
                    checked={formResponse.scheduleHours?.includes(
                      "Hourly (overnight)"
                    )}
                    onClick={(e) => handleScheduleHours(e.target.value)}
                  />
                  Hourly (overnight)
                </FormRadioLabel>
              </FormFieldset>

              <FormInputLabel>
                What is your weekly availability? *
              </FormInputLabel>
              <FormInputError radio>
                {formErrors.scheduleDays && formErrors.scheduleDays}
              </FormInputError>
              <FormFieldset>
                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="I am NOT available on weekdays"
                    name="scheduleDays"
                    checked={
                      formResponse.scheduleDays ===
                      "I am NOT available on weekdays"
                    }
                    onClick={(e) =>
                      handleFormResponse("scheduleDays", e.target.value)
                    }
                  />
                  I am NOT available on weekdays
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="I am NOT available on weekends"
                    name="scheduleDays"
                    checked={
                      formResponse.scheduleDays ===
                      "I am NOT available on weekends"
                    }
                    onClick={(e) =>
                      handleFormResponse("scheduleDays", e.target.value)
                    }
                  />
                  I am NOT available on weekends
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="I can work every other weekend"
                    name="scheduleDays"
                    checked={
                      formResponse.scheduleDays ===
                      "I can work every other weekend"
                    }
                    onClick={(e) =>
                      handleFormResponse("scheduleDays", e.target.value)
                    }
                  />
                  I can work every other weekend
                </FormRadioLabel>
              </FormFieldset>
              <ButtonAndMessage>
                <SubmitButton type="button" onClick={submit}>
                  Submit application
                </SubmitButton>
                <FormInputError radio>
                  <br />
                  {submitSuccess ? (
                    <div>Thank you</div>
                  ) : (
                    formErrors.axios && <div>Error. Please try again later</div>
                  )}
                </FormInputError>
              </ButtonAndMessage>
            </FormGroup>
          </FormGrid>
        </FormContainer>
      </FormBackground>
      <LearnMoreContainer>
        <LearnMoreDiv id="contact_us">
          <TextSection>
            <PhoneIcon src={telephoneRing} alt="phone icon" />
            <LearnMoreText>
              Want to learn more about Life Home Care?
            </LearnMoreText>
          </TextSection>
          <LearnMoreButton href={`mailto:${CONTACT.MAIL}`}>
            EXPLORE
          </LearnMoreButton>
          <LearnMoreButton mobile href={`tel:${CONTACT.PHONE}`}>
            EXPLORE
          </LearnMoreButton>
        </LearnMoreDiv>
      </LearnMoreContainer>
    </Layout>
  );
};

export default Careers;
